<template>
    <div :class="isMobile ? 'tab-div' : 'tab-div-small-margin'">
        <PillMenuBar v-if="navMenuItems && navMenuItems.length > 0" class="main-menu-bar"
            :navigationItems="navMenuItems" headerType="main" style="margin-bottom:10px;" >
            
        </PillMenuBar>

        <EducationCourseList :loading="coursesLoading" :courses="courses" :limit="limit" :offset="offset" :total="total" @change-page="onChangePage"/>
        
        
    </div>

</template>

<script>
import PillMenuBar from '../components/menu/PillMenuBar.vue';

import EducationCourseList from '../components/education/EducationCourseList';

import GroupService from '../service/GroupService';
import BrowserUtils from '../utilities/BrowserUtils';
//import router from '../router';
//import { GROUP_CONTEST_CATEGORIES } from '../common/constants';
//import EventBus from '../event-bus';
//import UserUtils from '../utilities/UserUtils';




export default {
    name: 'Education',
    components: {
        PillMenuBar,
        EducationCourseList,
    },
    data() {
        return {
            

            coursesLoading: true,
           
            courses: [],
            
            limit: 5,
            offset: 0,
            total: 0,
            
            navMenuItems: [
                { label: 'Education', to: '/education' , selected: true},
            ],

            
        }
    },

    computed: {
        isMobile() {
            return BrowserUtils.isMobile();
        },
    },

    watch: {
        
    },

    mounted() {
       

       
    },
    activated() {
         this.fetchEducationCourses();
    },
    

    methods: {
        

       
        
        onChangePage(pageState) {
             //console.log("got change page event with page state of ", pageState)
            this.offset = pageState.first;
            this.fetchEducationCourses();
        },
        

        async fetchEducationCourses() {
            this.coursesLoading = true;
            return GroupService.getEducationCourses(this.limit, this.offset).then(resp => {
                this.coursesLoading = false;
                if (resp.data.status == "success") {

                    this.courses = resp.data.courses;
                    this.total = resp.data.totalCount
                    window.scrollTo(0, 0);
                    
                }
            });
        },

       
    }
};
</script>

<style>
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: #32364e !important;
    color: white !important;
}
</style>

<style scoped>
*:not(.pi),
::v-deep(.p-component) {
    font-family: "Trebuchet MS", "Verdana";
}

.tab-div-small-margin {
    margin: 0px 16px;
}

.pill-menu-bar.sub-header {
    margin-bottom: 1rem;
}

.content-cycle-container {
    display: flex;
    justify-content: center;
    gap: 4px;
    align-items: center;
    margin-bottom: 1rem;
}

.main-menu-bar {
    margin: 1rem 1rem 0 1rem;
    padding: 0 !important;
}

.container {
    display: flex;
    flex-wrap: wrap;
}

::v-deep(.scroll-to-top-button) {
    right: 18vw;
}

::v-deep(.p-dropdown) {
    border: 2px solid #32364E;
    border-radius: 50px;
    padding: 2px;
    max-width: calc(100% - 50px);
}

::v-deep(.p-dropdown:hover, .p-dropdown:active, .p-dropdown:focus, .p-dropdown.p-focus, .p-inputwrapper-focus) {
    border-color: #32364e;
}

::v-deep(.p-dropdown-label) {
    color: #32364e;
    font-size: 1rem;
    padding: 2px 0px 2px 4px;
}

::v-deep(.p-dropdown-trigger) {
    width: 1.5rem;
}

::v-deep(.p-dropdown-trigger-icon) {
    color: #32364e;
    font-size: 0.9rem;
}

.go-back-button {
  padding: 8px 16px;
  border-style: solid;
  border-radius: 28px;
}

.p-dropdown:not(.p-disabled).p-focus,
::v-deep(.p-dropdown:not(.p-disabled):focus) {
    outline: none !important;
    box-shadow: none !important;
    border-color: unset;
}

@media (max-width: 760px) {

    .content-cycle-container {
        margin: 8px;
    }

    .main-menu-bar {
        margin: 0.5rem;
    }

    .card {
        border-radius: 0;
        margin-bottom: 2px;
        padding: 0 16px;
    }

    ::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem button) {
        background: #ffffff;
        border: solid #ffffff;
        border-width: 0 0 2px 0;
        padding: 6px;
    }

    ::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight button) {
        background: #ffffff;
        color: #33CC99;
        border: solid #33CC99;
        border-width: 0 0 2px 0;
        padding: 6px;
    }

    ::v-deep(.p-tabmenu-nav) {
        text-align: center;
        border-width: 0;
        display: flex;
        justify-content: space-around;
    }

    ::v-deep(.p-tabmenuitem) {
        padding-top: 0 !important;
    }
}
</style>