<template>
    <LoadingSpinner :loading='loading' v-if='loadingGroup'/>
    <div class="education-list-container" v-if="!loadingGroup">
        <div style="width:100%;">
            <template v-for="course in courses" :key="course.courseId">
                <div class="card" >

                    <div v-if="isMobile" class="flex-column">
                        <div class="flex-row">
                            <UserAvatar :imageSrc="(course.iconUrl ? course.iconUrl: '/assets/images/eduAccount.png')" size='lg' shape='roundedSquare'/>
                            <div class="flex-column" style="gap: 8px; padding: 0px 16px 16px;">
                                <span style="font-size: 20px;">
                                    {{ course?.name }}
                                </span>
                                
                               <div style="text-overflow: ellipsis;overflow: hidden;display: -webkit-box; -webkit-line-clamp: 7; -webkit-box-orient: vertical;">
                                    <span>{{course?.description}}</span>
                                   
                                </div>
                               
                                <Button @click='goToGroup(course)' class="green-button__secondary action-button">
                                    <span>Open</span>
                                </Button>
                               
                            </div>
                            <!-- <img v-if="course.product_id" class="course-list-icon" src="assets/images/paid-course.png"/> -->
                            <!-- <img v-else-if="group.isPrivate" class="course-list-icon" src="assets/images/lock.png" /> -->
                            <img v-if="course.productId " class="course-list-icon" src="assets/images/paid-course.png"  v-tooltip.top="'This course has a fee.'"/>
                            <img v-else-if="course.subscriptionRequired" class="course-list-icon" src="assets/images/paid-course.png"  v-tooltip.top="'A group subscription is required to access this course.'"/>
                            <img v-else class="course-list-icon" src="assets/images/free.png" v-tooltip.top="'This course is offered for free.'"/>

                        </div>
                       
                    </div>

                    <div v-else class="flex-row" >
                        <div class="flex-row" style="width: 80%;">
                            <UserAvatar :imageSrc="(course.iconUrl ? course.iconUrl: '/assets/images/eduAccount.png')" size='lg' shape='roundedSquare'/>
                            <div class="flex-column" style="gap: 8px; padding: 0px 16px 0px;">
                                <span style="font-size: 20px;">
                                    {{ course?.name }}
                                </span>
                                <div style="text-overflow: ellipsis;overflow: hidden;display: -webkit-box; -webkit-line-clamp: 7; -webkit-box-orient: vertical;">
                                    <span>{{course?.description}}</span>
                                   
                                </div>
                                <div>
                                   
                                    <span><small>Course provided by {{course.groupName}}</small></span>
                                </div>
                                
                                <Button label="Open" @click="goToGroup(course)" class="green-button__secondary action-button" :loading="loadingGroup">
                                    
                                </Button>
                                
                            </div>
                        </div>
                        <div class="data-container" style="width:15%;" >
                        </div>

                       
                        <img v-if="course.productId " class="course-list-icon" src="assets/images/paid-course.png"  v-tooltip.top="'This course has a fee.'"/>
                        <img v-else-if="course.subscriptionRequired" class="course-list-icon" src="assets/images/paid-course.png"  v-tooltip.top="'A group subscription is required to access this course.'"/>
                       
                        <img v-else class="course-list-icon" src="assets/images/free.png" v-tooltip.top="'This course is offered for free.'"/>
                         
                    </div>

                </div>
            </template>
            <Paginator :rows="limit" :first="offset" :totalRecords="total" @page="onPage"/>
        </div>
        
    </div>

    
        
</template>

<script>
// import Column from 'primevue/column';
// import DataTable from 'primevue/datatable';
import UserAvatar from "../profile/UserAvatar.vue";
import Paginator from 'primevue/paginator';
// import router from '../../../router';

import BrowserUtils from "../../utilities/BrowserUtils";

import LoadingSpinner from '../common/LoadingSpinner.vue';

// import UserService from '../../service/UserService';
// import EventBus from '../../event-bus';
// import { isAfter, isBefore, startOfDay, add } from 'date-fns';


export default {
    name: 'EducationCourseList',
    emits: [ 'change-page'],
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        courses: {
            type: Array,
            required: false
        },
         limit: {
            type: Number,
            required: true
        },

        offset: {
            type: Number,
            required: true
        },

        total: {
            type: Number,
            required: true
        }
    },
    components: {
        UserAvatar,
        Paginator,
        LoadingSpinner
        
    },
    data() {
        return {
            loadingGroup: false,
            
        }
    },
   

    computed: {
        isMobile() {
            return BrowserUtils.isMobile();
        },

        
    },

    activated() {
        this.loadingGroup = false;
    },
    
   
    methods: {

         onPage( pageState){
            this.$emit('change-page', pageState);
        },

      
        goToGroup( course) {//eslint-disable-line
           this.loadingGroup = true;
            this.$router.push('/groups/profile/' + course.groupId+'/course/'+course.courseId);
        },

        

       
    }
}
</script>

<style scoped>
.competition-list-icon {
    max-width: 5em;
    max-height: 5em;
}

.course-list-icon {
    max-width: 2em;
    max-height: 2em;
    margin-left: auto;
}
.education-list-container {
    display: flex;
    margin: 1rem 1rem 60px 1rem;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

::v-deep(table th),
::v-deep(table td) {
    padding: 0.4rem 0.4rem !important;
}

.hosted-by-label {
    color: #33CC99;
}

.hosted-by-label:hover {
    cursor: pointer;
}

.data-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.data-container * {
    min-width: 222px;
}

.action-button {
    padding: 8px 16px;
    border-style: solid;
    border-radius: 28px;
    text-align: center;
    width: 200px;
}

.action-button span {
    width: 100%;
}

@media (max-width: 760px) {
    .education-list-container {
        margin-left: 1rem;
    }
}
</style>